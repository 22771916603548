import React, { useEffect, useMemo, useRef } from "react";
import styled from "styled-components";
import { graphql } from "gatsby";
import gsap from "gsap";

import Layout from "../components/layout";
import SEO from "../components/seo";

import SectionTitle from "../components/section-title";
import Contact from "../components/contact";
import ImageGrid from "../components/image-grid";
import Description from "../components/description";

const Container = styled.section`
  margin-top: 125px;
`;

const Content = styled.div`
  margin-top: 1.25rem;

  p {
    font-size: 1rem;
  }

  ul {
    list-style-type: none;
    padding-left: 1.25rem;
  }

  li {
    position: relative;
    margin: 0.75rem 0;

    &::before {
      content: "";
      position: absolute;
      background-color: ${props => props.theme.colors.primary};
      width: 0.5rem;
      height: 0.5rem;
      left: -1.25rem;
      top: 50%;
      transform: translateY(-50%);
    }
  }
`;

export const query = graphql`
  query($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        images {
          childImageSharp {
            original {
              src
            }
          }
        }
        seo {
          description
          title
        }
      }
    }
  }
`;

const OfferPageTemplate = ({ data }) => {
  const offer = data.markdownRemark;

  const content = useRef(null);

  useEffect(() => {
    gsap.set(content.current.children, {
      opacity: 0,
    });

    gsap.fromTo(
      content.current.children,
      { opacity: 0, x: -50 },
      {
        opacity: 1,
        x: 0,
        duration: 2,
        stagger: 0.7,
        scrollTrigger: content.current,
      }
    );
  }, []);

  const images = useMemo(() => {
    return offer.frontmatter.images.map(
      image => image.childImageSharp.original.src
    );
  }, [offer.frontmatter.images]);

  return (
    <Layout>
      <SEO
        title={offer.frontmatter.seo.title}
        description={offer.frontmatter.seo.description}
      />
      <Container>
        <Description>
          <SectionTitle title={offer.frontmatter.title} />
          <Content
            ref={content}
            dangerouslySetInnerHTML={{ __html: offer.html }}
          />
        </Description>
      </Container>
      <ImageGrid images={images} columns={2} />
      <Contact />
    </Layout>
  );
};

export default OfferPageTemplate;
